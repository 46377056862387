import { TranslatorFunctionType } from 'src/types/localization';

export default function createTenantSelectorPlaceholder(
  portalOnboardingList: any,
  t: TranslatorFunctionType,
  placeholderValue: string
) {
  return portalOnboardingList?.map((portalOnboarding) => ({
    label: t(
      `${portalOnboarding?.tenantSelectorPlaceholder?.translationKey}`,
      `${portalOnboarding?.tenantSelectorPlaceholder?.label}`
    ),
    value: `${placeholderValue}_${portalOnboarding?.key}`,
    route: portalOnboarding?.route
  }));
}
