import React, { useEffect, useState } from 'react';
import TenantSelector from '../src/index';
import { MfePropsType } from '../src/types/mfeProps';
import projectNames from '../src/configs/projectNames';
import { TranslatorFunctionType } from '../src/types/localization';
import resources from '../src/assets/locale';
import { ShellRootProvider } from '../src/contexts/ShellRoot';

type TenantSelectorProps = {
  finishTenantSelector?: () => Promise<void>;
  errorCallback?: (error: Error) => Promise<void>;
  fullscreen?: boolean;
  preventReload?: boolean;
};

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  finishTenantSelector,
  fullscreen,
  errorCallback,
  preventReload = false,
  ...props
}: MfePropsType & TenantSelectorProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>();
  const { v1, v2 } = (window as any)?.Shell || {};
  const interfaces = {
    v1,
    v2
  };

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1?.localization
      ?.createTranslatorFunction?.(resources)
      .then((v) => setT(() => v));
  }, [v1]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ShellRootProvider {...{ interfaces, t }}>
        <TenantSelector
          {...{
            ...props,
            ...interfaces,
            finishTenantSelector,
            preventReload,
            errorCallback,
            fullscreen
          }}
        />
      </ShellRootProvider>
    </section>
  );
}
