/* eslint-disable @typescript-eslint/no-explicit-any */
type LoggerTypes = 'debug' | 'error' | 'log' | 'warn';
const preffix = 'JSHELL__@jarvis__react-shell-tenant-selector';

function getLogger(type: LoggerTypes) {
  return (...args: any[]) => {
    const logger = (window as any)?.Shell?.v1?.logger;

    const loggerFunction = logger?.[type] || console?.[type];

    loggerFunction?.(preffix, ...args);
  };
}

export default {
  debug: getLogger('debug'),
  error: getLogger('error'),
  log: getLogger('log'),
  warn: getLogger('warn')
};
