import React, { useMemo, useState, useCallback, useEffect } from 'react';
import ShellRootContext, {
  ShellRootContextType,
  ShellRootUseContextReturnType
} from './ShellRootContext';
import { ThemeProvider } from '@veneer/theme';
// import resources from '../../assets/locale';

const defaultResources = {};

const ShellRootProvider = ({
  children,
  // TODO: Check what's that
  localizationResources,
  interfaces,
  ...props
}: ShellRootContextType) => {
  const { localization } = interfaces.v1;
  const [commonsGlobalTranslate, setCommonsGlobalTranslate] =
    useState<ShellRootUseContextReturnType['globalTranslate']>();

  const themeMode = interfaces?.v1?.theme?.getUserThemeMode?.();
  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  );

  useEffect(() => {
    localization
      ?.getGlobalTranslatorFunction?.()
      ?.then((t) => setCommonsGlobalTranslate(() => t));
  }, [localization]);

  const globalTranslate = useCallback<
    ShellRootUseContextReturnType['globalTranslate']
  >(
    (...args: Parameters<ShellRootUseContextReturnType['globalTranslate']>) => {
      return commonsGlobalTranslate?.(...args) || '';
    },
    [commonsGlobalTranslate]
  );

  return (
    <ShellRootContext.Provider
      value={{ ...props, globalTranslate, interfaces }}
    >
      <ThemeProvider mode={themeMode}>
        <TranslatorProvider
          resources={localizationResources || defaultResources}
        >
          {children}
        </TranslatorProvider>
      </ThemeProvider>
    </ShellRootContext.Provider>
  );
};

export default ShellRootProvider;
