import { DataValveCDMEvent } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';

export function createSimpleUiEvent(eventDetail: unknown): DataValveCDMEvent {
  return {
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: eventDetail,
    version: '1.4.0'
  };
}
