import styled from 'styled-components';
import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import { isIE } from './utils/ie';

type CustomType = {
  isselect?: boolean;
};

type themeModeType = {
  themeMode: string;
};

export const Background = styled.div<themeModeType>`
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  ${({ themeMode }) => {
    if (themeMode === 'dark') return 'background: ' + tokens.color.gray7 + ';';
    return 'background: ' + tokens.color.gray2 + ';';
  }}
`;

export const Container = styled(Card)`
  width: 480px;
`;

export const Content = styled.div<CustomType>`
  padding: ${({ isselect }) => (isselect ? '58.5px' : '48px')};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormDiv = styled.div<CustomType>`
  display: flex;
  flex-direction: column;
  ${({ isselect }) => isselect && 'gap: 12px;'};
  ${() => isIE() && `margin-top: ${tokens.layout.size6};`}
`;

export const Title = styled.h1<themeModeType>`
  font-size: 24px;
  line-height: 28px;
  ${({ themeMode }) => {
    if (themeMode === 'dark') return 'color: ' + tokens.color.gray2 + ';';
    if (themeMode === 'contrast') return 'color: ' + tokens.color.white + ';';
    return 'color: ' + tokens.color.gray10 + ';';
  }}
`;

export const SubTitle = styled.h2<themeModeType>`
  font-size: 16px;
  line-height: 19px;
  ${() => isIE() && `margin-top: ${tokens.layout.size2};`}

  ${({ themeMode }) => {
    if (themeMode === 'dark') return 'color: ' + tokens.color.gray1 + ';';
    if (themeMode === 'contrast') return 'color: ' + tokens.color.white + ';';
    return 'color: ' + tokens.color.gray7 + ';';
  }}
`;
